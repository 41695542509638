import { NewAnimatePresenceProps } from "@/types/FrameMotion";
import { AnimatePresence } from "framer-motion";
import React, { FC } from "react";

interface Props {
	children: React.ReactNode;
}

const CustomAnimation = ({ children }: Props) => {
	const NewAnimatePresence: FC<NewAnimatePresenceProps> = AnimatePresence;

	return <NewAnimatePresence>{children}</NewAnimatePresence>;
};

export default CustomAnimation;
