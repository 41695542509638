import Image from "next/image";
import { customPreLoader } from "@/helpers/customPreLoader";

interface RC1ImageProps {
	className?: any;
	src: string;
	objectFit?: any;
	objectPosition?: string;
	layout?: any;
	alt: any;
	height?: number | string;
	width?: number | string;
	sizes?: any;
	loading?: any;
	quality?: number | string;
	draggable?: boolean;
	priority?: boolean;
}

const RC1Image = ({
	src,
	alt,
	height,
	width,
	objectFit,
	layout,
	loading,
	objectPosition,
	quality,
	draggable,
	priority = false,
	className,
}: RC1ImageProps): JSX.Element => {
	return (
		<>
			<Image
				loader={customPreLoader}
				src={src}
				alt={alt}
				className={className}
				objectFit={objectFit}
				loading={loading}
				layout={layout}
				height={height}
				width={width}
				quality={quality}
				unoptimized={true}
				draggable={draggable}
				priority={priority}
				objectPosition={objectPosition}
			/>
		</>
	);
};

export default RC1Image;
