/* eslint-disable camelcase */

export interface IYotpoParams {
	scores: number | Array<number>,
	pictured: boolean,
	seat: boolean,
	crfs: Array<any>,
}

export interface IYotpoResponse {
	pagination:    Pagination;
	bottomline:    Bottomline;
	products:      Product[];
	product_tags:  null;
	reviews:       Review[];
}

export interface Bottomline {
	total_review:             number;
	average_score:            number;
	total_organic_reviews:    number;
	organic_average_score:    number;
	star_distribution:        { [key: string]: number };
}


export interface Pagination {
	page:     number;
	per_page: number;
	total:    number;
}

export interface Product {
	id:                        number;
	domain_key:                string;
	name:                      string;
	social_links:              SocialLinks;
	embedded_widget_link:      string;
	testimonials_product_link: string;
	product_link:              string;
	image_url:                 string;
}

export interface SocialLinks {
	linkedin:      string;
	facebook:      string;
	twitter:       string;
	google_oauth2: string;
}

export interface Review {
	id:               number;
	score:            number;
	votes_up:         number;
	votes_down:       number;
	content:          string;
	title:            string;
	created_at:       string;
	deleted:          boolean;
	verified_buyer:   boolean;
	source_review_id: null;
	sentiment:        number;
	custom_fields:    { [key: string]: CustomField };
	product_id:       number;
	is_incentivized:  boolean;
	user:             User;
}

export interface CustomField {
	title:      Title;
	form_id:    number;
	field_type: FieldType;
	value:      string;
}

export enum FieldType {
	SingleChoice = "SingleChoice",
}

export enum Title {
	Age = "Age",
	Experience = "Experience",
}

export interface User {
	user_id:             number;
	social_image:        null;
	user_type:           UserType;
	is_social_connected: number;
	display_name:        string;
}

export enum UserType {
	User = "User",
}
