import moment from "moment";
import momentTimeZone from "moment-timezone";

/**
 * Use `checkRangeOfDate()` to check betwen dates
 * @param {string} dateFrom
 * @param {string} dateTo
 * @returns {boolean} is `true` or `false`
 */

export const checkRangeOfDate = (dateFrom: string, dateTo: string): Boolean => {
	let curretDate = momentTimeZone
		.tz("America/New_York")
		.format("YYYY-MM-DDTHH:mm");

	if (dateTo === undefined || dateTo === null) return;
	if (dateFrom === undefined || dateFrom === null) return;

	const compareDate = moment(curretDate, "YYYY-MM-DDTHH:mm");
	const startDate = moment(dateFrom, "YYYY-MM-DDTHH:mm:");
	const endDate = moment(dateTo, "YYYY-MM-DDTHH:mm");

	return compareDate.isBetween(startDate, endDate);
};
