import styles from "./UserName.module.scss";
import Cookies from "js-cookie";
import LoginUserSvg from "public/login.svg";

const UserName = () => {
	let userData: any = Cookies.get("userData");

	if (userData !== undefined) {
		userData = JSON.parse(userData);
		let email = userData.email;
		let firstName = userData.first_name;
		let status = "";

		for (let i = 0; i < userData.tags.length; i++) {
			if (userData.tags[i] === "Alpha Team Member") {
				status = "REDCON1 " + userData.tags[i];
			}
		}

		return (
			<section className={styles.userData}>
				<div>
					<h2>{firstName}</h2>
					<h3>{email}</h3>
					<span>{status}</span>
				</div>
			</section>
		);
	} else {
		return (
			<section className={styles.userData}>
				<a href="https://accounts.redcon1.com/account/login?react=true">
					<LoginUserSvg /> <span>Sign In</span>
				</a>
			</section>
		);
	}
};

export default UserName;
