/* eslint-disable no-unused-vars */
import { createContext } from "react";
import { ISwatches } from "interfaces/swatches";

interface contextProps {
	swatches: ISwatches[];
	loading: boolean;
	statusMenu: boolean;
	// method
	startLoading: () => void;
	stopLoading: () => void;
	toggleMenu: (status: boolean) => void;
}

export const UiContext = createContext({} as contextProps);
