/* eslint-disable camelcase */
import { IYotpo, IYotpoMediasMerge } from "@/types/yotpo.interfaces";
import { createContext } from "react";
import { IYotpoParams, IYotpoResponse } from "./yotpo.interfaces";
import { IYotpoQuestion } from "../../interfaces/question.interfaces";
import {
	FormDataQuestion,
	FormDataReview,
	NotificationOnCreation,
} from "./YotpoProvider";
import { IProduct } from "@/types/products.interfaces";

export interface IReviewPagination {
	page: number;
	per_page: number;
	total: number;
}

export interface IReviewMetadata {
	average_score: number;
	total_review: number;
	star_distribution: {
		1: number;
		2: number;
		3: number;
		4: number;
		5: number;
	};
}
interface contextProps {
	accessToken: string;
	reviews: IYotpo[];
	reviewPagination: IReviewPagination;
	reviewMetadata: IReviewMetadata;
	currentPage: number;
	productId: string;
	clearFilter: boolean;
	loadingData: boolean;
	errorOnData: boolean;
	questionData: IYotpoQuestion[];
	creationResource: NotificationOnCreation | null;
	formReview: boolean;
	formQuestion: boolean;
	modalMediaData: IYotpoMediasMerge | null;
	topRatedProduct: IProduct[];
	allProducts: IProduct[];
	loadingAllProduct: boolean;
	filterParams: IYotpoParams | null;
	hasReview: boolean;
	setFilterParams: (params: IYotpoParams) => void;
	setYotpoReviewMetadata: (metadata: IReviewMetadata) => void;
	setCurrentPagination: (id: string, currentPage: number) => void;
	setPagination: (pagination: IReviewPagination) => void;
	setMetadata: (reviewMetadata: IReviewMetadata) => void;
	searchReview: (id: string, query: string) => Promise<void>;
	setProductId: (id: string) => void;
	setClearFilter: () => void;
	setLoadingData: (status: boolean) => void;
	// filterByParams: (params: IYotpoParams) => Promise<void>;
	sendReview: (data: FormDataReview) => Promise<void>;
	setResourceWasCreated: (status: NotificationOnCreation | null) => void;
	toggleFormReview: (toggle: boolean) => void;
	toggleFormQuestion: (toggle: boolean) => void;
	sendQuestion: (data: FormDataQuestion) => Promise<void>;
	setModalMediaData: (data: IYotpoMediasMerge | null) => void;
	sendVote: (reviewId: number, status: string) => Promise<void>;
	getReviewForProduct: (id: string) => Promise<IYotpoResponse>;
}

export const YotpoContext = createContext({} as contextProps);
