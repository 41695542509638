/* eslint-disable camelcase */
import React from "react";
import { IYotpo, IYotpoMediasMerge } from "@/types/yotpo.interfaces";
import { FunctionComponent, useEffect, useReducer } from "react";
import { YotpoContext, yotpoReducer } from ".";
import { IReviewPagination, IReviewMetadata } from "./YotpoContext";
import { IYotpoParams } from "./yotpo.interfaces";
import { IYotpoQuestion } from "interfaces/question.interfaces";
import { IProduct } from "@/types/products.interfaces";

export type FormDataReview = {
	email: string;
	username: string;
	review: string;
	title: string;
	exp: string;
	age: string;
	url: string;
	score: number;
};

export type FormDataQuestion = {
	email: string;
	username: string;
	question: string;
	title: string;
	url: string;
};

export type NotificationOnCreation = {
	title: string;
	message: string;
};

export interface YotpoState {
	accessToken: string;
	reviews: IYotpo[];
	currentPage: number;
	reviewMetadata: IReviewMetadata;
	reviewPagination: IReviewPagination;
	productId: string;
	clearFilter: boolean;
	loadingData: boolean;
	errorOnData: boolean;
	questionData: IYotpoQuestion[];
	creationResource: NotificationOnCreation | null;
	formReview: boolean;
	formQuestion: boolean;
	modalMediaData: IYotpoMediasMerge | null;
	topRatedProduct: IProduct[];
	allProducts: IProduct[];
	loadingAllProduct: boolean;
	filterParams: IYotpoParams | null;
	hasReview: boolean;
}

const YOTPO_INITIAL_STATE: YotpoState = {
	accessToken: "",
	reviews: [],
	currentPage: 1,
	reviewMetadata: {
		average_score: 0,
		total_review: 0,
		star_distribution: {
			1: 0,
			2: 0,
			3: 0,
			4: 0,
			5: 0,
		},
	},
	reviewPagination: {
		page: 0,
		per_page: 0,
		total: 0,
	},
	productId: null,
	clearFilter: false,
	loadingData: false,
	errorOnData: false,
	questionData: [],
	creationResource: null,
	formReview: false,
	formQuestion: false,
	modalMediaData: null,
	topRatedProduct: [],
	allProducts: [],
	loadingAllProduct: false,
	filterParams: null,
	hasReview: false,
};

interface Props {
	children: React.ReactNode;
}

export const YotpoProvider: FunctionComponent<Props> = ({ children }) => {
	const [state, dispatch] = useReducer(yotpoReducer, YOTPO_INITIAL_STATE);

	useEffect(() => {
		//authYotpo();
		const fetchQandA = async (id: string) => {
			try {
				const data = await fetch(
					`https://api.yotpo.com/products/${process.env.NEXT_PUBLIC_YOTPO_API_KEY}/${id}/questions`
				);
				const { response } = await data.json();

				dispatch({
					type: "[YotpoQuestion] -  Set data",
					payload: response.questions,
				});
			} catch (error) {}
		};

		if (state.productId) {
			fetchQandA(state.productId);
		}
	}, [state.productId]);

	useEffect(() => {
		const fetchYotpoReviesByProduct = async (
			id: string,
			pagination: number
		) => {
			setLoadingData(true);
			try {
				const response = await fetch(
					`https://api-cdn.yotpo.com/v1/widget/${process.env.NEXT_PUBLIC_YOTPO_API_KEY}/products/${id}/reviews.json?page=${pagination}&per_page=10`,
					{
						method: "GET",
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
					}
				);
				const data = await response.json();
				setMetadata(data.response?.bottomline);
				setReviews(data.response?.reviews);
				setPagination(data.response?.pagination);
				setLoadingData(false);
				if (data.response?.products.length !== 0) {
					dispatch({ type: "[Yotpo] - Has reviews", payload: true });
				} else {
					dispatch({ type: "[Yotpo] - Has reviews", payload: false });
				}
			} catch (err) {
				setLoadingData(false);
				dispatch({ type: "[Yotpo] - Has reviews", payload: false });
			}
		};

		if (
			state.productId &&
			state.currentPage &&
			state.filterParams === null
		) {
			fetchYotpoReviesByProduct(state.productId, state.currentPage);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		state.currentPage,
		state.productId,
		state.clearFilter,
		state.filterParams,
	]);

	useEffect(() => {
		const filterByParams = async (params: IYotpoParams) => {
			const newParams = { ...params };
			try {
				const response = await fetch(
					`https://api-cdn.yotpo.com/v1/reviews/${process.env.NEXT_PUBLIC_YOTPO_API_KEY}/filter.json?page=${state.currentPage}&per_page=10`,
					{
						method: "POST",
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							domain_key: state.productId,
							...newParams,
						}),
					}
				);
				const data = await response.json();
				setReviews(data.response?.reviews);
				setLoadingData(false);
			} catch (error) {
				setLoadingData(false);
			}
		};

		if (state.filterParams) {
			filterByParams(state.filterParams);
		}
	}, [state.filterParams, state.productId, state.currentPage]);

	const getProductId = (str: string) => {
		const productId = str.split("/").pop();

		return productId;
	};

	const setYotpoReviewMetadata = (metadata: IReviewMetadata) => {
		dispatch({ type: "[Yotpo] - Set review metadata", payload: metadata });
	};

	const setPagination = (pagination: IReviewPagination) => {
		dispatch({
			type: "[Yotpo] - Set review pagination",
			payload: pagination,
		});
	};

	const setMetadata = (reviewMetadata: IReviewMetadata) => {
		dispatch({
			type: "[Yotpo] - Set review metadata",
			payload: reviewMetadata,
		});
	};

	const setReviews = (reviews: IYotpo[]) => {
		dispatch({ type: "[Yotpo] - Set review by product", payload: reviews });
	};

	const setCurrentPagination = (id: string, currentPage: number) => {
		dispatch({ type: "[Yotpo] - Set current page", payload: currentPage });
	};

	const setProductId = (id: string) => {
		const productId = getProductId(id);

		dispatch({ type: "[Yotpo] - Set product id", payload: productId });
	};

	const searchReview = async (id: string, query: string) => {
		setLoadingData(true);
		try {
			const response = await fetch(
				`https://api-cdn.yotpo.com/v1/reviews/${process.env.NEXT_PUBLIC_YOTPO_API_KEY}/filter.json?&per_page=10`,
				{
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						domain_key: state.productId,
						free_text_search: query,
					}),
				}
			);
			const data = await response.json();
			setReviews(data.response?.reviews);
			setLoadingData(false);
		} catch (error) {
			setLoadingData(false);
		}
	};

	const setClearFilter = () => {
		dispatch({
			type: "[Yotpo] - Clear Filter",
			payload: (state.clearFilter = !state.clearFilter),
		});
		dispatch({ type: "[Yotpo] - Set filter Params", payload: null });
		dispatch({ type: "[Yotpo] - Set current page", payload: 1 });
	};

	const setLoadingData = (status: boolean) => {
		dispatch({ type: "[Yotpo] - Loading", payload: status });
	};

	const setFilterParams = (params: IYotpoParams) => {
		let newParams = { ...state.filterParams, params };
		dispatch({
			type: "[Yotpo] - Set filter Params",
			payload: newParams.params,
		});
	};

	const setResourceWasCreated = (status: NotificationOnCreation | null) => {
		dispatch({
			type: "[Yotpo] - Review created notification",
			payload: status,
		});
	};

	const sendReview = async (data: FormDataReview) => {
		const { email, score, review, title, username, url, age, exp } = data;

		dispatch({ type: "[Yotpo] - Loading", payload: true });

		try {
			const options = {
				method: "POST",
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					sku: state.productId,
					product_title: title,
					product_url: url,
					display_name: username,
					email,
					review_content: review,
					review_title: title,
					review_score: score,
					appkey: process.env.NEXT_PUBLIC_YOTPO_API_KEY,
					custom_fields: {
						"--6894": exp,
						"--6892": age,
					},
				}),
			};

			await fetch("https://api.yotpo.com/v1/widget/reviews", options);
			dispatch({ type: "[Yotpo] - Data Error", payload: false });
			dispatch({ type: "[Yotpo] - Loading", payload: false });
			setResourceWasCreated({
				title: "THANK YOU FOR POSTING A REVIEW!",
				message:
					"We value your input. Share your review so everyone else can enjoy it too.",
			});
		} catch (error) {
			dispatch({ type: "[Yotpo] - Data Error", payload: true });
			dispatch({ type: "[Yotpo] - Loading", payload: false });
			setResourceWasCreated(null);
		}
	};

	const sendQuestion = async (data: FormDataQuestion) => {
		const { email, username, question, url, title } = data;

		dispatch({ type: "[Yotpo] - Loading", payload: true });

		try {
			const options = {
				method: "POST",
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					sku: state.productId,
					product_title: title,
					product_url: url,
					display_name: username,
					email,
					review_content: question,
					review_title: title,
					appkey: process.env.NEXT_PUBLIC_YOTPO_API_KEY,
				}),
			};

			await fetch(
				"https://api.yotpo.com/questions/send_confirmation_mail",
				options
			);
			dispatch({ type: "[Yotpo] - Data Error", payload: false });
			dispatch({ type: "[Yotpo] - Loading", payload: false });
			setResourceWasCreated({
				title: "THANK YOU FOR POSTING A QUESTION!",
				message:
					"Please click on the link in the confirmation email we just sent you to submit your question.Your question will appear on the site once someone answers it.",
			});
		} catch (error) {
			dispatch({ type: "[Yotpo] - Data Error", payload: true });
			dispatch({ type: "[Yotpo] - Loading", payload: false });
			setResourceWasCreated(null);
		}
	};

	const toggleFormReview = (toggle: boolean) => {
		dispatch({ type: "[Yotpo] - Toogle form review", payload: toggle });
	};

	const toggleFormQuestion = (toggle: boolean) => {
		dispatch({ type: "[Yotpo] - Toogle form question", payload: toggle });
	};

	const setModalMediaData = (data: IYotpoMediasMerge | null) => {
		dispatch({ type: "[Yotpo] - Set Modal media", payload: data });
	};

	const sendVote = async (reviewId: number, status: string) => {
		try {
			const options = {
				method: "POST",
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
			};

			if (status === "up") {
				let checkVoteUp = state.reviews.filter(
					(review) => review.id === reviewId && review.votes_up !== 0
				);
				dispatch({
					type: "[Yotpo] - Clear vote down",
					payload: reviewId,
				});
				if (checkVoteUp.length !== 0) return;
				dispatch({
					type: "[Yotpo] - Update vote up",
					payload: reviewId,
				});
				await fetch(
					`https://api.yotpo.com/reviews/${reviewId}/vote/up`,
					options
				);
			} else {
				let checkVoteDown = state.reviews.filter(
					(review) =>
						review.id === reviewId && review.votes_down !== 0
				);
				dispatch({
					type: "[Yotpo] - Clear vote up",
					payload: reviewId,
				});
				if (checkVoteDown.length !== 0) return;
				dispatch({
					type: "[Yotpo] - Update vote down",
					payload: reviewId,
				});
				await fetch(
					`https://api.yotpo.com/reviews/${reviewId}/vote/down`,
					options
				);
			}
		} catch (error) {}
	};

	const getReviewForProduct = async (id: string) => {
		const decodeId = getProductId(id);
		try {
			const res = await fetch(
				`https://api-cdn.yotpo.com/v1/widget/${process.env.NEXT_PUBLIC_YOTPO_API_KEY}/products/${decodeId}/reviews.json`,
				{
					method: "GET",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				}
			);
			const { response } = await res.json();
			return response;
		} catch (err) {
			return null;
		}
	};

	return (
		<YotpoContext.Provider
			value={{
				...state,
				setFilterParams,
				setYotpoReviewMetadata,
				setCurrentPagination,
				setPagination,
				setMetadata,
				searchReview,
				setProductId,
				setClearFilter,
				setLoadingData,
				sendReview,
				setResourceWasCreated,
				toggleFormReview,
				toggleFormQuestion,
				sendQuestion,
				setModalMediaData,
				sendVote,
				getReviewForProduct,
			}}
		>
			{children}
		</YotpoContext.Provider>
	);
};
