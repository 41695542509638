import { ISwatches } from "interfaces/swatches";
import { UiState } from "./";

type UiActionType =
	| {
			type: "[Ui] - Updated swatch data";
			payload: ISwatches[];
	  }
	| { type: "[Ui] - Start loading" }
	| { type: "[Ui] - Stop loading" }
	| { type: "[Ui] - Toggle menu"; payload: boolean };

export const uiReducer = (state: UiState, action: UiActionType): UiState => {
	switch (action.type) {
		case "[Ui] - Updated swatch data":
			return {
				...state,
				swatches: [...action.payload],
			};
		case "[Ui] - Start loading":
			return { ...state, loading: true };
		case "[Ui] - Stop loading":
			return { ...state, loading: false };
		case "[Ui] - Toggle menu":
			return { ...state, statusMenu: action.payload };

		default:
			return state;
	}
};
