import React, { memo, useEffect, useRef, useState } from "react";
import Link from "next/link";
import styles from "./SlideCustomText.module.scss";
import useSortDataByDate from "@/hooks/useSortDataByDate";
import useWindowSize from "@/hooks/useWindowSize";

interface SlideCustomTextProps {
	textBannerData: Array<any>;
}

const SlideCustomText = ({
	textBannerData,
}: SlideCustomTextProps): JSX.Element => {
	const elementLinks = useRef<HTMLDivElement[]>([]);

	elementLinks.current = [];
	const slideshow = useRef(null);
	const mainContainer = useRef(null);
	const intervalSlideShow = useRef(null);
	const [mounted, setMounted] = useState(false);
	const [sortByDateSliders] = useSortDataByDate(textBannerData);
	const [maxWidth, setMaxWidth] = useState<number>();
	const [wi] = useWindowSize();
	const breakPoint = 900;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const setWidth = () => {
		if (slideshow.current?.children.length) {
			if (slideshow.current.children.length > 0) {
				let w: number[] = [];
				for (let item of slideshow.current.children) {
					w.push(item.offsetWidth);
				}
				let d = Math.max.apply(null, w);

				d += 3;

				setMaxWidth(d > 600 ? 600 : d);
				slideshow.current.style.width = `${
					wi <= breakPoint ? "100%" : maxWidth + "px"
				}`;
			}
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const next = () => {
		if (
			slideshow.current?.children.length &&
			typeof maxWidth !== "undefined"
		) {
			if (slideshow.current.children.length > 0) {
				const firstElement = slideshow.current.children[0];
				//const size = slideshow.current.children[0].offsetWidth;

				slideshow.current.style.transition = `400ms ease-out all`;
				slideshow.current.style.transform = `translateX(-${
					wi <= breakPoint ? "100%" : maxWidth + "px"
				})`;
				slideshow.current.style.width = `${
					wi <= breakPoint ? "100%" : maxWidth + "px"
				}`;

				const transition = () => {
					slideshow.current.style.transition = `none`;
					slideshow.current.style.transform = `translateX(0)`;

					slideshow.current.appendChild(firstElement);

					slideshow.current.removeEventListener(
						"transitionend",
						transition
					);
				};

				slideshow.current.addEventListener("transitionend", transition);
			}
		}
	};

	const prev = () => {
		if (
			slideshow.current.children.length > 0 &&
			typeof maxWidth !== "undefined"
		) {
			const index = slideshow.current.children.length - 1;
			const latElement = slideshow.current.children[index];
			slideshow.current.insertBefore(
				latElement,
				slideshow.current.firstChild
			);

			slideshow.current.style.transition = "none";
			slideshow.current.style.transform = `translateX(-${
				wi <= breakPoint ? "100%" : maxWidth + "px"
			})`;
			slideshow.current.style.width = `${
				wi <= breakPoint ? "100%" : maxWidth + "px"
			}`;

			setTimeout(() => {
				slideshow.current.style.transition = `400ms ease-out all`;
				slideshow.current.style.transform = `translateX(0)`;
			}, 30);
		}
	};

	useEffect(() => {
		setMounted(true);
		return () => {
			setMounted(false);
			elementLinks.current = [];
		};
	}, []);

	useEffect(() => {
		if (mounted) {
			setWidth();
			intervalSlideShow.current = setInterval(() => {
				next();
			}, 7000);
		}
		return () => clearInterval(intervalSlideShow.current);
	}, [mounted, next, setWidth]);

	useEffect(() => {
		for (let elment of elementLinks.current) {
			elment.style.width = `${
				wi <= breakPoint ? "100%" : maxWidth + "px"
			}`;
		}
	}, [wi, maxWidth]);

	const addTorefs = (el: HTMLDivElement) => {
		if (el && !elementLinks.current.includes(el)) {
			elementLinks.current.push(el);
		}
	};

	if (!sortByDateSliders) return null;

	return (
		<>
			{mounted && (
				<div className={styles.mainContainer} ref={mainContainer}>
					<div
						className={styles.prev}
						onClick={prev}
						role="button"
						onKeyPress={prev}
						tabIndex={0}
					>
						&larr;
					</div>
					<div className={styles.showSlide} ref={slideshow}>
						{sortByDateSliders.map((item: any, index: any) => (
							<div
								key={index}
								className={styles.slide}
								ref={addTorefs}
							>
								<Link
									prefetch={false}
									href={item?.fields?.destinationUrl || "/"}
								>
									<a>{item?.fields?.label}</a>
								</Link>
							</div>
						))}
					</div>
					<div
						className={styles.next}
						onClick={next}
						role="button"
						onKeyPress={next}
						tabIndex={0}
					>
						&rarr;
					</div>
				</div>
			)}
		</>
	);
};

export default memo(SlideCustomText);
