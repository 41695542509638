// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
	allowUrls: ["https://redcon1.com/"],
	dsn:
		SENTRY_DSN ||
		"https://0cfe0740684f425295ce089d5ef5b974@o951937.ingest.sentry.io/5901101",
	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 0,
	integrations: [
		new Sentry.BrowserTracing({
			tracingOrigins: ["http://localhost:3000", process.env.VERCEL_URL],
		}),
	],
	ignoreErrors: [
		// Random plugins/extensions
		"top.GLOBALS",
		"Non-Error exception captured",
		"Non-Error promise rejection captured",
		"Network Error",
		/https:\/\/assets\.gorgias\.chat/,
		"Error module.exports(chunks/pages/node_modules/axios/lib/core/createError)",
		"ChunkLoadError ?(gorgias-chat-bundle)",
		// Generic error code from errors outside the security sandbox
		"Script error.",
	],
	allowUrls: [/http:\/\/redcon1\.com/],
	beforeSendTransaction(event) {
		// Modify or drop the event here
		if (event.transaction === "/gorgias-chat-bundle.js") {
			// Don't send the event to Sentry
			return null;
		}
		return event;
	},
	beforeSend(event) {
		try {
			if (
				event.exception.values[0].stacktrace.frames[0].filename ===
				`<anonymous>`
			) {
				return null;
			}
		} catch (e) {}

		return event;
	},
	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
});
