import { OrderGrooveState } from "./";
import { IUser } from "interfaces";

type OrderGrooveActionType =
	| {
			type: "[Account] - Fetch user data";
			payload: IUser | null;
	  }
	| {
			type: "[OrderGroove] - Get subscriptions";
			payload: any | null;
	  };

export const ordergrooveReducer = (
	state: OrderGrooveState,
	action: OrderGrooveActionType
): OrderGrooveState => {
	switch (action.type) {
		case "[Account] - Fetch user data":
			return {
				...state,
				user: action.payload,
			};
		case "[OrderGroove] - Get subscriptions":
			return {
				...state,
				orders: [...action.payload],
			};
		default:
			return state;
	}
};
