import { AccountState } from "./";
import { IUser } from "interfaces";

type AccountActionType = {
	type: "[Account] - Fetch user data";
	payload: IUser | null;
};

export const accountReducer = (
	state: AccountState,
	action: AccountActionType
): AccountState => {
	switch (action.type) {
		case "[Account] - Fetch user data":
			return {
				...state,
				user: action.payload,
			};

		default:
			return state;
	}
};
