import { checkRangeOfDate } from "@/helpers/checkRangeDate";
// import useGlobalContext from "context/GlobalContext";
import { useEffect, useState } from "react";

const useSortDataByDate = (data: Array<any>): Array<any> => {
	// const { state } = useGlobalContext();
	const [sortByDateSliders, setSortByDaySliders] = useState<any[]>([]);
	useEffect(() => {
		let sortSlides = [];
		if (data) {
			if (data[0]["fields"] !== undefined) {
				data.forEach((item) => {
					if (item?.fields?.endDate) {
						if (
							checkRangeOfDate(
								item?.fields?.startDate,
								item?.fields?.endDate
							)
						) {
							sortSlides.push(item);
						}
					} else {
						sortSlides.push(item);
					}
				});
			} else {
				data.forEach((item) => {
					if (item?.endDate) {
						if (checkRangeOfDate(item?.startDate, item?.endDate)) {
							sortSlides.push(item);
						}
					} else {
						sortSlides.push(item);
					}
				});
			}
		}
		setSortByDaySliders(sortSlides);
	}, [data]);

	return [sortByDateSliders];
};

export default useSortDataByDate;
