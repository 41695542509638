import Script from "next/script";
import styles from "./socialIcons.module.scss";
import Link from "next/link";
interface SocialIconsProps {
	icons: any[];
}

const SocialIcons = ({ icons }: SocialIconsProps) => {
	if (!icons) return null;
	return (
		<>
			<ul className={styles.socialBar}>
				{icons.map(({ title, image, destinationUrl }, index) => {
					return (
						<Link key={index} href={destinationUrl}>
							<a rel="me">
								<li>
									<img
										src={image.url + "?fm=webp"}
										alt={title}
									/>
								</li>
							</a>
						</Link>
					);
				})}
			</ul>
			<div>
				<Script
					type="text/javascript"
					async={true}
					src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=T6rZi4"
				/>
				<div
					className={`${"klaviyo-form-ShuWfW"} ${styles.klaviyoForm}`}
				></div>
			</div>
		</>
	);
};

export default SocialIcons;
