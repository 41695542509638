import { IGlobalStatus } from "interfaces/globalStatus.interfaces";
import { IProduct } from "interfaces/products.interfaces";
import { createContext } from "react";

interface contextProps {
	redcon1: IGlobalStatus;
	product: IProduct | false;
	discount: string | null;
	removeDiscount: () => void;
	// eslint-disable-next-line no-unused-vars
	updateGlobalData: (
		cartTotal: number,
		cartQty: number,
		discountableValue: boolean | null
	) => void;
	setProduct: (product: IProduct | false) => void;
}

export const GlobalContext = createContext({} as contextProps);
