import { FunctionComponent, useState, useEffect } from "react";
import styles from "./Footer.module.scss";
import FooterBody from "../FooterBody/FooterBody";

interface FooterProps {
	content: any;
}

const Footer: FunctionComponent<FooterProps> = ({ content }: FooterProps) => {
	const [mounted, setMounted] = useState(false);
	useEffect(() => {
		setMounted(true);
	}, []);

	return (
		<>
			{mounted && (
				<footer className={`${styles.footer}`}>
					<div className={styles.wrap}>
						<FooterBody data={content} />
					</div>
				</footer>
			)}
		</>
	);
};

export default Footer;
