import React, {
	FunctionComponent,
	useContext,
	useMemo,
	useReducer,
} from "react";
import { OrderGrooveContext, ordergrooveReducer } from ".";
import { AccountContext } from "context/account";
import { IUser } from "interfaces";

export interface OrderGrooveState {
	user: IUser | null;
	orders: any;
}

const ORDERGROOVE_INITIAL_STATE: OrderGrooveState = {
	user: null,
	orders: null,
};

interface Props {
	children: React.ReactNode;
}

export const OrderGrooveProvider: FunctionComponent<Props> = ({ children }) => {
	const [state, dispatch] = useReducer(
		ordergrooveReducer,
		ORDERGROOVE_INITIAL_STATE
	);

	const axios = require("axios").default;
	const { user } = useContext(AccountContext);

	useMemo(() => {
		if (user) {
			dispatch({
				type: "[Account] - Fetch user data",
				payload: user,
			});
		}
	}, [user]);

	async function getHeaders() {
		const { createHmac } = await import("crypto");
		const SigField = user.userID;
		const TimeStamp = Math.round(+new Date() / 1000);
		const ConcatSigTs = SigField + "|" + TimeStamp;
		const OgHash = process.env.NEXT_PUBLIC_OG_HASH;
		let OGSignature = createHmac("sha256", OgHash)
			.update(ConcatSigTs)
			.digest("base64");

		let Auth = {
			// eslint-disable-next-line camelcase
			public_id: "6e14b0000cc411eba9cdbc764e10b970",
			ts: TimeStamp,
			// eslint-disable-next-line camelcase
			sig_field: SigField,
			sig: OGSignature,
		};

		return JSON.stringify(Auth);
	}

	async function orderGrooveRequest(
		_url: String,
		_method: String = "GET",
		_body: any = null
	) {
		return getHeaders().then((response) => {
			var options = {
				method: _method,
				url: _url,
				headers: {
					Accept: "application/json",
					Authorization: response,
					"Content-Type": "application/json",
				},
			};

			if (_body != null) {
				options["data"] = _body;
			}

			return axios.request(options);
		});
	}

	function ogAPI(url: any, method: any, body: any, callBackFunction) {
		orderGrooveRequest(url, method, body)
			.then((response) => {
				return callBackFunction(response);
			})
			.catch((error) => {
				console.warn(error);
				return false;
			});
	}

	async function getSubscriptions() {
		const getAllOrders = async () => {
			try {
				let response = await orderGrooveRequest(
					"https://restapi.ordergroove.com/orders/?page_size=100&status=1",
					"GET",
					null
				);

				dispatch({
					type: "[OrderGroove] - Get subscriptions",
					payload: response.data.results,
				});
			} catch (error) {
				/* eslint no-console: "off" */
				dispatch({
					type: "[OrderGroove] - Get subscriptions",
					payload: "failed",
				});
				console.warn(`no orders found.`);
			}
		};

		getAllOrders();
	}

	async function sendSubscriptionNow(order: any) {
		try {
			await orderGrooveRequest(
				"https://restapi.ordergroove.com/orders/" +
					order +
					"/send_now/",
				"PATCH",
				null
			).then((request: any) => {
				console.log(request.data);
			});
		} catch (error: any) {
			console.warn(`faild to send subscription: ${error}`);
		}
	}

	async function getShippingAddress(addressId: any) {
		return await orderGrooveRequest(
			"https://restapi.ordergroove.com/addresses/" + addressId + "/"
		)
			.then((response) => {
				return response.data.results;
			})
			.catch(() => {
				return false;
			});
	}

	return (
		<OrderGrooveContext.Provider
			value={{
				...state,
				orderGrooveRequest,
				getSubscriptions,
				sendSubscriptionNow,
				getShippingAddress,
				ogAPI,
			}}
		>
			{children}
		</OrderGrooveContext.Provider>
	);
};
