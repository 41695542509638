import { useContext, useEffect, useState } from "react";
import MenuItems from "../MenuItems/MenuItems";
import styles from "./AccountMenu.module.scss";
import { AccountContext } from "context/account/AccountContext";

interface AccountMenuProps {
	data: any[];
}

const AccountMenu = ({ data }: AccountMenuProps): JSX.Element => {
	const { user } = useContext(AccountContext);
	const [items, setItems] = useState(null);

	useEffect(() => {
		user && user.tags.includes("Tier Operator")
			? setItems(data)
			: setItems(data.filter((item) => item.label != "Tier Operator"));
	}, [data, user]);

	return (
		<ul className={styles.leftMenuItem}>
			{items &&
				items.map((item) => (
					<li key={item.label}>
						<MenuItems item={item} />
					</li>
				))}
		</ul>
	);
};

export default AccountMenu;
