import { useEffect, useState } from "react";
import { motion, Variants, Transition } from "framer-motion";
import styles from "./LoadingButton.module.scss";

const loadigContainerVariant: Variants = {
	start: {
		transition: {
			staggerChildren: 0.1,
		},
	},
	end: {
		transition: {
			staggerChildren: 0.1,
		},
	},
};

const loadingCircleVariant: Variants = {
	start: {
		y: "0%",
	},
	end: {
		y: "90%",
	},
};

const loadingCircleTransition: Transition = {
	duration: 0.3,
	repeat: Infinity,
	repeatType: "reverse",
	ease: "easeInOut",
};

const LoadingButton = () => {
	const [mounted, setMounted] = useState(false);
	useEffect(() => {
		setMounted(true);
	}, []);
	return (
		mounted && (
			<motion.div
				className={styles.loadinContainer}
				initial="start"
				animate="end"
				variants={loadigContainerVariant}
			>
				<motion.span
					className={styles.loadinCircle}
					variants={loadingCircleVariant}
					transition={loadingCircleTransition}
				></motion.span>
				<motion.span
					className={styles.loadinCircle}
					variants={loadingCircleVariant}
					transition={loadingCircleTransition}
				></motion.span>
				<motion.span
					className={styles.loadinCircle}
					variants={loadingCircleVariant}
					transition={loadingCircleTransition}
				></motion.span>
			</motion.div>
		)
	);
};

export default LoadingButton;
