import styles from "./MenuList.module.scss";
import { Fragment } from "react";
import MenuItems from "../MenuItems/MenuItems";

interface MenuListProps {
	data: any;
}

const MenuList = ({ data }: MenuListProps): JSX.Element => {
	return (
		<ul className={styles.leftMenuItem}>
			{data &&
				data.length &&
				data.map(({ fields }) => {
					return (
						<Fragment key={fields.label}>
							<li className={styles.leftMenuHeader}>
								{fields.title}
							</li>
							{fields.links?.length !== 0 &&
								fields.links.map((fields: any) => (
									<li key={fields.fields.label}>
										<MenuItems item={fields.fields} />
									</li>
								))}
						</Fragment>
					);
				})}
		</ul>
	);
};

export default MenuList;
