export const menuStyle = {
	bmBurgerButton: {
		position: "relative",
		width: "20px",
		height: "20px",
		left: "20px",
		top: "22px",
		zIndex: "3",
	},
	bmBurgerBars: {
		background: "#fff",
	},
	bmBurgerBarsHover: {
		background: "#a90000",
	},
	bmCrossButton: {
		height: "24px",
		width: "34px",
		left: "10px",
		top: "55px",
	},
	bmCross: {},
	bmMenuWrap: {
		height: "100%",
		top: "0px",
		left: "0px",
	},
	bmMenu: {
		background: "#fff",
		padding: "1em 0 0 0",
		fontSize: "1.15em",
	},
	bmMorphShape: {
		fill: "#373a47",
	},
	bmItemList: {
		color: "#2b2b2b",
		padding: "0",
	},
	bmItem: {
		display: "inline-block",
	},
	bmOverlay: {
		background: "rgba(0, 0, 0, 0.3)",
		top: "0",
	},
};
