import { createContext } from "react";
import { AddNotification, NotificationState } from "./NotificationProvider";
import { NOTIFICATION } from "./type";

interface contextProps {
	description: string;
	id: string;
	message: string;
	variant: NOTIFICATION;
	timestamp: number;
	timeout?: number;
	notifications: NotificationState[];
	// method
	clearNotification: (id?: string | string[]) => void;
	setNotification: (notification: AddNotification) => void;
}

export const NotificationContext = createContext({} as contextProps);
