/* eslint-disable camelcase */
import { IYotpo, IYotpoMediasMerge } from '@/types/yotpo.interfaces';
import { IYotpoParams , NotificationOnCreation, YotpoState } from './';
import { IReviewPagination, IReviewMetadata } from './YotpoContext';
import { IYotpoQuestion } from 'interfaces/question.interfaces';

type NameActionType =
   | { type: '[Yotpo] - Set Access token', payload: string }
   | { type: '[Yotpo] - Set review by product', payload: IYotpo[] }
   | {type: '[Yotpo] - Set review pagination', payload: IReviewPagination}
   | {type: '[Yotpo] - Set review metadata', payload: IReviewMetadata}
   | {type: '[Yotpo] - Set current page', payload: number}
   | {type: '[Yotpo] - Set product id', payload: string}
   | {type: '[Yotpo] - Clear Filter',payload: boolean}
   | {type: '[Yotpo] - Loading',payload: boolean}
   | {type: '[Yotpo] - Data Error',payload: boolean}
   | {type: '[YotpoQuestion] -  Set data',payload: IYotpoQuestion[]}
   | {type: '[Yotpo] - Review created notification', payload: NotificationOnCreation | null}
   | {type: '[Yotpo] - Toogle form review', payload: boolean}
   | {type: '[Yotpo] - Toogle form question', payload: boolean}
   | {type: '[Yotpo] - Set Modal media', payload: IYotpoMediasMerge | null}
   | {type: '[Yotpo] - Update vote up',payload: number}
   | {type: '[Yotpo] - Update vote down',payload: number}
   | {type: '[Yotpo] - Clear vote up',payload: number}
   | {type: '[Yotpo] - Set filter Params', payload: IYotpoParams | null}
   | {type: '[Yotpo] - Clear vote down',payload: number}
   | {type: '[Yotpo] - Has reviews',payload: boolean};;
   

export const yotpoReducer = (state: YotpoState, action: NameActionType): YotpoState => {
   switch (action.type) {
	   case '[Yotpo] - Set Access token':
		   return {
			   ...state,
			   accessToken: action.payload
			 };
		case '[Yotpo] - Set review by product':
			return {
				...state,
				reviews: action.payload
			}
		case '[Yotpo] - Set review pagination':
			return {
				...state,
				reviewPagination: action.payload
			}
		case '[Yotpo] - Set review metadata':
			return {
				...state,
				reviewMetadata: action.payload
			}
		case '[Yotpo] - Set current page':
			return {
				...state,
				currentPage: action.payload
			}
		case '[Yotpo] - Set product id':
			return {
				...state,
				productId: action.payload
			}
		case '[Yotpo] - Clear Filter':
			return {
				...state,
				clearFilter: action.payload
			}
		case '[Yotpo] - Loading': {
			return {
				...state,
				loadingData: action.payload
			}
		}
		case '[YotpoQuestion] -  Set data': {
			return {
				...state,
				questionData: action.payload
			}
		}
		case '[Yotpo] - Data Error':
			return {
				...state,
				errorOnData: action.payload
			}
		case '[Yotpo] - Review created notification':
			return {
				...state,
				creationResource: action.payload
			}
		case '[Yotpo] - Toogle form review':
			return {
				...state,
				formReview: action.payload
			}
		case '[Yotpo] - Toogle form question':
			return {
				...state,
				formQuestion: action.payload
			}
		case '[Yotpo] - Set Modal media':
			return {
				...state,
				modalMediaData: action.payload
			}
		case '[Yotpo] - Set filter Params':
			return {
				...state,
				filterParams: action.payload
			}
		case '[Yotpo] - Has reviews':
			return {
				...state,
				hasReview: action.payload
			}
		case '[Yotpo] - Update vote up':
			return {
				...state,
				reviews: state.reviews.map(review => {
					
					if(review.id === action.payload) {
						return {...review, votes_up: review.votes_up + 1}
					} else {
						return review
					}
				})
			}
		case '[Yotpo] - Update vote down':
			return {
				...state,
				reviews: state.reviews.map(review => {
					if(review.id === action.payload) {
						return {...review, votes_down: review.votes_down + 1}
					} else {
						return review
					}
				})
			}
		case '[Yotpo] - Clear vote up':
			return {
				...state,
				reviews: state.reviews.map(review => {
					if(review.id === action.payload) {
						return {...review, votes_up: 0}
					} else {
						return review
					}
				})
			}
			case '[Yotpo] - Clear vote down':
			return {
				...state,
				reviews: state.reviews.map(review => {
					if(review.id === action.payload) {
						return {...review, votes_down: 0}
					} else {
						return review
					}
				})
			}
	default:
	  return state;
   }
};