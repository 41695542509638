import React, { FunctionComponent, useReducer, useEffect } from "react";
import { UiContext, uiReducer } from "./";
import { ISwatches } from "interfaces/swatches";
import client from "@/services/contentfulClient";

export interface UiState {
	swatches: ISwatches[];
	statusMenu: boolean;
	loading: boolean;
}

const UI_INITIAL_STATE: UiState = {
	swatches: [],
	statusMenu: false,
	loading: false,
};

interface Props {
	children: React.ReactNode;
}

export const UiProvider: FunctionComponent<Props> = ({ children }) => {
	const [state, dispatch] = useReducer(uiReducer, UI_INITIAL_STATE);

	useEffect(() => {
		const findAllSwatches = async () => {
			try {
				let response: any = await client.getEntries({
					/* eslint-disable camelcase */
					content_type: "globalProperties",
					"fields.handle": "us_retail-global-properties",
				});
				dispatch({
					type: "[Ui] - Updated swatch data",
					payload: response.items[0].fields.swatchData,
				});
			} catch (error) {
				/* eslint no-console: "off" */
				dispatch({
					type: "[Ui] - Updated swatch data",
					payload: [],
				});
				console.warn(`no page with handle found.`);
			}
		};
		findAllSwatches();
	}, []);

	const startLoading = () => {
		dispatch({ type: "[Ui] - Start loading" });
	};
	const stopLoading = () => {
		dispatch({ type: "[Ui] - Stop loading" });
	};

	const toggleMenu = (status: boolean) => {
		dispatch({ type: "[Ui] - Toggle menu", payload: status });
	};

	return (
		<UiContext.Provider
			value={{
				...state,
				// method
				startLoading,
				stopLoading,
				toggleMenu,
			}}
		>
			{children}
		</UiContext.Provider>
	);
};
