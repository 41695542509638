import { useEffect, useState } from "react";
import styles from "./GeoRedirectBar.module.scss";
import Axios from "axios";
import Buttons from "../Buttons/Buttons";
import Link from "next/link";
import Cookies from "js-cookie";

const GeoRedirectBar = () => {
	const accessKey = process.env.NEXT_PUBLIC_IP_API;
	const [country, setCountry] = useState<string>(null);
	const [countryCode, setCountryCode] = useState<string>(null);
	const [redirectUrl, setRedirectUrl] = useState<string>(null);
	const [display, setDisplay] = useState<boolean>(null);

	useEffect(() => {
		const geoCookie = Cookies.get("GeoRedirect");

		geoCookie == "optedOut" ? setDisplay(false) : setDisplay(true);

		const getLocation = () => {
			Axios.get(
				`https://api.ipapi.com/api/check?access_key=${accessKey}`
			).then((response) => {
				let data = response.data;
				setCountry(data.country_name);
				setCountryCode(data.country_code);
			});
		};

		const countries = {
			"United States": "https://redcon1.com",
			"United Kingdom": "https://uk.redcon1.com",
			Canada: "https://redcon1.ca",
			Japan: "https://redcon1.jp",
		};

		const site = window.location.origin;

		getLocation();

		let user = {
			country: country,
			url: site,
		};

		for (const website in countries) {
			if (user.country === website) {
				user.url != countries[website]
					? setRedirectUrl(countries[website])
					: null;
			}
		}
	}, [accessKey, country]);

	const removeBar = () => {
		Cookies.set("GeoRedirect", "optedOut", { expires: 14 });
		setDisplay(false);
	};

	return (
		display &&
		redirectUrl && (
			<>
				<div className={styles.geoRedirectBar}>
					<div className={styles.redirectContainer}>
						It seems like your in the {country}. Redirect to{" "}
						<Link prefetch={false} href={redirectUrl} passHref>
							<a>Redcon1 {countryCode} Store?</a>
						</Link>
					</div>
					<div className={styles.geoRedirectButtons}>
						<Buttons
							variant="fill"
							text={"Yes"}
							destination={redirectUrl}
							color="primary"
							width="normal"
						/>
						<Buttons
							variant="fill"
							text={"No"}
							color="primary"
							width="normal"
							callBack={removeBar}
						/>
					</div>
				</div>
			</>
		)
	);
};

export default GeoRedirectBar;
