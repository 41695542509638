/* eslint-disable no-unused-vars */
import { createContext } from "react";
import { IUser } from "interfaces/index";

interface contextProps {
	user: IUser | null;
	orders: any;
	// methods
	orderGrooveRequest: (_url: String, _method: String, _body: any) => any;
	getSubscriptions: () => void;
	sendSubscriptionNow: (order: any) => void;
	getShippingAddress: (addressId: any) => any;
	ogAPI: (url: any, method: any, body: any, callBackFunction: any) => any;
}

export const OrderGrooveContext = createContext({} as contextProps);
