import { FunctionComponent } from "react";
import { useContext } from "react";
import { slide as Menu } from "react-burger-menu";
import { menuStyle } from "../../helpers/menuStyle";
import { UiContext } from "context/ui";
interface Props {
	children: any;
}

const BurgerMenu: FunctionComponent<Props> = ({ children }: Props) => {
	const { statusMenu, toggleMenu } = useContext(UiContext);

	return (
		<Menu
			customCrossIcon={<img alt="burger-menu" src="/List-Menu.svg" />}
			data-cy="burge-menu"
			isOpen={statusMenu}
			styles={menuStyle}
			onStateChange={(state) => toggleMenu(state.isOpen)}
		>
			{children}
		</Menu>
	);
};

export default BurgerMenu;
