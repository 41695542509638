import { useContext, useEffect, useState } from "react";
import Link from "next/link";
import styles from "./RightNavigation.module.scss";
import CartSvg from "public/cart.svg";
import { motion } from "framer-motion";
import { CartContext } from "context/cart";

const variants = {
	hidden: {
		opacity: 0,
		scale: 0.5,
	},
	show: {
		opacity: 1,
		scale: 1,
		transition: {
			delay: 0.5,
			type: "spring",
		},
	},
};

const CartRightNavigation = (): JSX.Element => {
	const { cart } = useContext(CartContext);
	const [mounted, setMounted] = useState(false);
	useEffect(() => {
		setMounted(true);
	}, []);

	return (
		mounted && (
			<Link prefetch={false} href={"/cart"}>
				<a className={styles.cartShop}>
					<CartSvg width={22} height={22} />
					{cart && (
						<motion.div
							animate={cart.length ? "show" : "hidden"}
							variants={variants}
							className={styles.productBadget}
						>
							{cart.reduce(
								(quantity, prod) => quantity + prod.quantity,
								0
							)}
						</motion.div>
					)}
				</a>
			</Link>
		)
	);
};

export default CartRightNavigation;
