import { NotificationState } from "./";

type NotificationActionType = { type: "[Notification] - ActionName" };

export const notificationReducer = (
	state: NotificationState,
	action: NotificationActionType
): NotificationState => {
	switch (action.type) {
		case "[Notification] - ActionName":
			return {
				...state,
			};

		default:
			return state;
	}
};
