import { ICartList } from "interfaces";
import { checkRangeOfDate } from "./checkRangeDate";
import { v4 as uuidv4 } from "uuid";
import md5 from "md5";
import { IDeal } from "interfaces/deal.interfaces";

export const checKDealAreInCartProducts = (product: ICartList[], deal: any) => {
    return product.filter((item) => item.deal === deal.deal);
};

export const watchForDeal = (
    deals: IDeal[] = [],
    total: number,
    products: ICartList[]
) => {
    let setDeal = [];
    let productTagsForDeal: string[] = [];

    if (products) {
        products.forEach((p) => {
            if (p.tags) {
                p.tags.forEach((t) => {
                    productTagsForDeal.push(t);
                });
            }
        });
    }

    setDeal = deals
        ?.filter((deal:any) => {
            if (deal.productTagRestriction) {
                if (productTagsForDeal.includes(deal.productTagRestriction)) {
                    return deal;
                }
            }
            return total * 100 >= deal.minPriceThreshold ? deal : null;
        })
        .filter((item:any) =>{
            return checkRangeOfDate(
                item.startDate,
                item.endDate
            )
        })
        .map((item:any) => ({
            ...item,
            dealId: uuidv4(),
            deal: `${md5(
                JSON.stringify(
                    `${item?.title}${item?.startDate}${item?.endDate}${item?.giftMessage}${item?.minPriceThreshold}`
                )
            )}`,
        }));

    return setDeal;
};

export const ramdomNumber = () => Math.floor(Math.random() * Date.now());

export const handleDealsInCart = (
    inCart: any,
    total: number,
    products: ICartList[]
) => {
    let productTagsForDeal: string[] = [];

    if (products) {
        products.forEach((p) => {
            if (p.tags) {
                p.tags.forEach((t) => {
                    productTagsForDeal.push(t);
                });
            }
        });
    }

    let cart = inCart
        .filter((item: any) => item)
        .map((deal: any) => {
            if (deal.productTagRestriction) {
                if (productTagsForDeal.includes(deal.productTagRestriction)) {
                    return deal;
                }
            }
            return total * 100 >= deal.minPriceThreshold ? deal : null;
        })
        .filter((deal: any) => deal);

    let getProductWithNoDeal = inCart.filter((item: any) => !item.deal);
    let newCart = cart.concat(getProductWithNoDeal);

    return newCart;
};

export const middlewareForDeal = (deals: Array<any>, cart: Array<any>) => {

    let newDealList = [];
    let currentCart = cart
        .map((item) => (item.deal ? item : null))
        .filter((item) => item);

    currentCart.forEach((product) => {
        if (product.deal) {
            newDealList = deals.filter(
                (deal: any) => deal.deal !== product.deal
            );
        }
    });
    return newDealList.length !== 0 ? newDealList : deals;
};

export const clearDataInDeal = (data: any) => {
    return data?.deals.filter((d: any) => !d.products.includes(undefined));
};
