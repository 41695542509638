import React, { useCallback, useState } from "react";
import { v4 as uuid } from "uuid";

import useInterval from "hooks/useInterval";
import { FunctionComponent, useReducer } from "react";
import { NotificationContext, notificationReducer } from "./";
import { NOTIFICATION } from "./type";

const INTERVAL = 1000;
const DEFAULT_TIMEOUT = 1000;

export interface NotificationState {
	description: string;
	id: string;
	message: string;
	variant: NOTIFICATION;
	timestamp: number;
	timeout?: number;
}

const NOTIFICATION_INITIAL_STATE: NotificationState = {
	description: null,
	id: null,
	message: null,
	variant: NOTIFICATION.ALERT,
	timestamp: null,
	timeout: 0,
};

export type OptionalExceptFor<T, TRequired extends keyof T> = Partial<T> &
	Pick<T, TRequired>;
export type AddNotification = OptionalExceptFor<NotificationState, "message">;

interface Props {
	children: React.ReactNode;
}

export const NotificationProvider: FunctionComponent<Props> = ({
	children,
}) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [state, dispatch] = useReducer(
		notificationReducer,
		NOTIFICATION_INITIAL_STATE
	);
	const [notifications, setNotifications] = useState<NotificationState[]>([]);

	// Append a new notification (or override existing by id)
	const setNotification = useCallback(
		(notification: AddNotification) => {
			const existing = notifications.find(
				(n) => n.id === notification.id
			);
			const nextNotifications = existing
				? notifications.map((n) =>
						n.id === notification.id
							? { ...existing, ...notification }
							: n
				  )
				: notifications.concat({
						id: uuid(),
						timestamp: new Date().getTime(),
						variant: NOTIFICATION.ERROR,
						description: "",
						...notification,
				  });
			setNotifications(nextNotifications);
		},
		[notifications, setNotifications]
	);

	// Clear notification(s) by id, or clear ALL notifications
	const clearNotification = useCallback(
		(id?: string | string[]) => {
			if (!id) {
				setNotifications([]);
			} else {
				const ids = Array.isArray(id) ? id : [id];
				const nextNotifications = notifications.filter(
					({ id }) => !ids.includes(id)
				);
				setNotifications(nextNotifications);
			}
			try {
				setNotifications([]);
			} catch (e) {}
		},
		[notifications, setNotifications]
	);

	// Set up interval to auto-expire notifications
	const handleExpireNotifications = useCallback(
		(currentTime) => {
			if (notifications.length) {
				const expiredIds = notifications.reduce((acc, n) => {
					const isExpired =
						n.timestamp <=
						currentTime - (n.timeout || DEFAULT_TIMEOUT);
					return isExpired && n.timeout !== null
						? acc.concat(n.id)
						: acc;
				}, []);
				if (expiredIds.length) {
					clearNotification(expiredIds);
				}
			}
		},
		[notifications, clearNotification]
	);

	useInterval(handleExpireNotifications, INTERVAL);

	return (
		<NotificationContext.Provider
			value={{
				...state,
				notifications,
				setNotification,
				clearNotification,
			}}
		>
			{children}
		</NotificationContext.Provider>
	);
};
